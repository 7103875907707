import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {OurStatsSection,
    OurStatsLayout,
    HeadingLayout,
    OurProjectsHeading,
    SingleStatLayout,
    StatImage,
    StatHeading,
    StatPara
} from './ourstats.style';

import { useStaticQuery, graphql } from 'gatsby';

const OurStats = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        homeJson{
            OurStats{
                OurProjectsHeading
                StatDetails{
                    StatImage
                    StatHeading
                    StatPara
                }

            }
        }
    }
  `);

    return(
        <OurStatsSection>
            <Container>
                <OurStatsLayout>
                    <HeadingLayout>
                        <OurProjectsHeading>{JSONData.homeJson.OurStats.OurProjectsHeading}</OurProjectsHeading>
                    </HeadingLayout>
                    <Row>
                        <Col lg="3" md="6">
                            <SingleStatLayout>
                                <StatImage src={JSONData.homeJson.OurStats.StatDetails[0].StatImage} alt=""/>
                                <StatHeading>{JSONData.homeJson.OurStats.StatDetails[0].StatHeading}</StatHeading>
                                <StatPara>{JSONData.homeJson.OurStats.StatDetails[0].StatPara}</StatPara>
                            </SingleStatLayout>
                        </Col>
                        <Col lg="3" md="6">
                            <SingleStatLayout>
                                <StatImage src={JSONData.homeJson.OurStats.StatDetails[1].StatImage} alt=""/>
                                <StatHeading>{JSONData.homeJson.OurStats.StatDetails[1].StatHeading}</StatHeading>
                                <StatPara>{JSONData.homeJson.OurStats.StatDetails[1].StatPara}</StatPara>
                            </SingleStatLayout>
                        </Col>
                        <Col lg="3" md="6">
                            <SingleStatLayout>
                                <StatImage src={JSONData.homeJson.OurStats.StatDetails[2].StatImage} alt=""/>
                                <StatHeading>{JSONData.homeJson.OurStats.StatDetails[2].StatHeading}</StatHeading>
                                <StatPara>{JSONData.homeJson.OurStats.StatDetails[2].StatPara}</StatPara>
                            </SingleStatLayout>
                        </Col>
                        <Col lg="3" md="6">
                            <SingleStatLayout>
                                <StatImage src={JSONData.homeJson.OurStats.StatDetails[3].StatImage} alt=""/>
                                <StatHeading>{JSONData.homeJson.OurStats.StatDetails[3].StatHeading}</StatHeading>
                                <StatPara>{JSONData.homeJson.OurStats.StatDetails[3].StatPara}</StatPara>
                            </SingleStatLayout>
                        </Col>
                    </Row>
                </OurStatsLayout>
            </Container>
        </OurStatsSection>

    );
}

export default OurStats;