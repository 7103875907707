import React from 'react';
import {AboutSection,AboutInner,
    AboutLeft,AboutRight,AboutLeftHeading,AboutLeftPara,
    AboutBtn,AboutRowReverse,MissionVisionPara,ListTextLayout,MissionVisionHeading,
    AboutRightImgFirstHolder,AboutRightImgSecondHolder,AboutLeftImgThirdHolder
} from './about.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";
import Fade from "react-reveal/Fade";

const About  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        homeJson{
            About{
                AboutRightImgFirst{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                AboutRightImgSecond{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                AboutLeftImgThird{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                AboutLeftHeading
                AboutLeftPara
                MissionVisionHeading1
                MissionVisionPara1
                MissionVisionHeading2
                MissionVisionPara2
                AboutBtn
            }
        }
    }
  `);

    return (
        <AboutSection id="AboutContainer">
            <Container>
                <AboutInner>
                    <AboutRowReverse>
                        <Col md="6">
                            <Fade right delay={50}>
                                <AboutRight>
                                    <AboutRightImgFirstHolder>
                                    <GatsImg 
                                        fluid={JSONData.homeJson.About.AboutRightImgFirst.childImageSharp.fluid} 
                                        alt=""
                                        className="AboutRightImgFirst"
                                    />
                                    </AboutRightImgFirstHolder>
                                    <AboutRightImgSecondHolder>
                                    <GatsImg 
                                        fluid={JSONData.homeJson.About.AboutRightImgSecond.childImageSharp.fluid} 
                                        alt=""
                                        className="AboutRightImgSecond"
                                    />
                                    </AboutRightImgSecondHolder>
                                    <AboutLeftImgThirdHolder>
                                    <GatsImg 
                                        fluid={JSONData.homeJson.About.AboutLeftImgThird.childImageSharp.fluid} 
                                        alt=""
                                        className="AboutLeftImgThird"
                                    />
                                    </AboutLeftImgThirdHolder>
                                </AboutRight>
                            </Fade>
                        </Col>

                        <Col md="6">
                            <Fade left delay={1*50}>
                            <AboutLeft>
                                <AboutLeftHeading>
                                {JSONData.homeJson.About.AboutLeftHeading}
                                </AboutLeftHeading>
                                <AboutLeftPara>
                                {JSONData.homeJson.About.AboutLeftPara}
                                </AboutLeftPara>
                                <ListTextLayout>
                                    <MissionVisionHeading>{JSONData.homeJson.About.MissionVisionHeading1}</MissionVisionHeading>
                                    <MissionVisionPara>{JSONData.homeJson.About.MissionVisionPara1}</MissionVisionPara>
                                </ListTextLayout>
                                <ListTextLayout>
                                    <MissionVisionHeading>{JSONData.homeJson.About.MissionVisionHeading2}</MissionVisionHeading>
                                    <MissionVisionPara>{JSONData.homeJson.About.MissionVisionPara2}</MissionVisionPara>
                                </ListTextLayout>
                                <AboutBtn href="#">
                                {JSONData.homeJson.About.AboutBtn}
                                </AboutBtn>
                            </AboutLeft>
                            </Fade>
                        </Col>
                    </AboutRowReverse>
                </AboutInner>
            </Container>
        </AboutSection>
    );
}
export default About;