import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {TestimonailSection,
    TestimonailsLayout,
    HeadingLayout,
    TestimonialHeading,
    TestimonialLayout,
    LeftQuote,
    TestimonialText,
    AuthorImageHolder,
    TestimonialAuthor,
    AuthorDesignation
} from './testimonials.style';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const OurStats = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        homeJson{
            Testimonials{
                TestimonialHeading
                TestimonialDetails{
                    TestimonialText
                    AuthorImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    TestimonialAuthor
                    AuthorDesignation
                }
            }
        }
    }
  `);

    return(
        <TestimonailSection>
            <Container>
                <TestimonailsLayout>
                    <HeadingLayout>
                        <TestimonialHeading>{JSONData.homeJson.Testimonials.TestimonialHeading}</TestimonialHeading>
                    </HeadingLayout>
                    <Row>
                        <Col lg="4">
                            <TestimonialLayout>
                                <LeftQuote />
                                <TestimonialText>{JSONData.homeJson.Testimonials.TestimonialDetails[0].TestimonialText}</TestimonialText>
                                <AuthorImageHolder>
                                    <GatsImg 
                                        fluid={JSONData.homeJson.Testimonials.TestimonialDetails[0].AuthorImage.childImageSharp.fluid} 
                                        alt=""
                                        imgStyle={{
                                            borderRadius:"100%"
                                        }}
                                    />
                                </AuthorImageHolder>
                                <TestimonialAuthor>{JSONData.homeJson.Testimonials.TestimonialDetails[0].TestimonialAuthor}</TestimonialAuthor>
                                <AuthorDesignation>{JSONData.homeJson.Testimonials.TestimonialDetails[0].AuthorDesignation}</AuthorDesignation>
                            </TestimonialLayout>
                        </Col>
                        <Col lg="4">
                            <TestimonialLayout>
                                <LeftQuote />
                                <TestimonialText>{JSONData.homeJson.Testimonials.TestimonialDetails[1].TestimonialText}</TestimonialText>
                                    <AuthorImageHolder>
                                        <GatsImg 
                                            fluid={JSONData.homeJson.Testimonials.TestimonialDetails[1].AuthorImage.childImageSharp.fluid} 
                                            alt=""
                                            imgStyle={{
                                                borderRadius:"100%"
                                            }}
                                        />
                                    </AuthorImageHolder>
                                <TestimonialAuthor>{JSONData.homeJson.Testimonials.TestimonialDetails[1].TestimonialAuthor}</TestimonialAuthor>
                                <AuthorDesignation>{JSONData.homeJson.Testimonials.TestimonialDetails[1].AuthorDesignation}</AuthorDesignation>
                            </TestimonialLayout>
                        </Col>
                        <Col lg="4">
                            <TestimonialLayout>
                                <LeftQuote />
                                <TestimonialText>{JSONData.homeJson.Testimonials.TestimonialDetails[2].TestimonialText}</TestimonialText>
                                <AuthorImageHolder>
                                    <GatsImg 
                                        fluid={JSONData.homeJson.Testimonials.TestimonialDetails[2].AuthorImage.childImageSharp.fluid} 
                                        alt=""
                                        imgStyle={{
                                            borderRadius:"100%"
                                        }}
                                    />
                                </AuthorImageHolder>
                                <TestimonialAuthor>{JSONData.homeJson.Testimonials.TestimonialDetails[2].TestimonialAuthor}</TestimonialAuthor>
                                <AuthorDesignation>{JSONData.homeJson.Testimonials.TestimonialDetails[2].AuthorDesignation}</AuthorDesignation>
                            </TestimonialLayout>
                        </Col>
                    </Row>
                </TestimonailsLayout>
            </Container>
        </TestimonailSection>

    );
}

export default OurStats;