import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {
    BlogSection,
    HeadingLayout,
    BlogHeading,
    BlogLayout,
    BlogSingleLayout,
    BlogTitle,
    BlogText,
    ReadMoreBtn
} from './blogs.style';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Blog = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        homeJson{
            Blog{
                BlogHeading
                BlogDetails{
                    BlogImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    BlogTitle
                    BlogText
                    ReadMoreBtn
                }
            }
        }
    }
  `);

    return(
        <BlogSection>
            <Container>
                <HeadingLayout>
                    <BlogHeading>
                    {JSONData.homeJson.Blog.BlogHeading}
                    </BlogHeading>
                </HeadingLayout>
                <BlogLayout>
                    <Row>
                        <Col md={6}>
                            <BlogSingleLayout>
                                <GatsImg 
                                    fluid={JSONData.homeJson.Blog.BlogDetails[0].BlogImage.childImageSharp.fluid} 
                                    alt=""
                                />
                                <BlogTitle>{JSONData.homeJson.Blog.BlogDetails[0].BlogTitle}</BlogTitle>
                                <BlogText>{JSONData.homeJson.Blog.BlogDetails[0].BlogText}</BlogText>
                                <ReadMoreBtn>{JSONData.homeJson.Blog.BlogDetails[0].ReadMoreBtn}</ReadMoreBtn>
                            </BlogSingleLayout>
                        </Col>

                        <Col md={6}>
                            <BlogSingleLayout>
                                <GatsImg 
                                    fluid={JSONData.homeJson.Blog.BlogDetails[1].BlogImage.childImageSharp.fluid} 
                                    alt=""
                                />
                                <BlogTitle>{JSONData.homeJson.Blog.BlogDetails[1].BlogTitle}</BlogTitle>
                                <BlogText>{JSONData.homeJson.Blog.BlogDetails[1].BlogText}</BlogText>
                                <ReadMoreBtn>{JSONData.homeJson.Blog.BlogDetails[1].ReadMoreBtn}</ReadMoreBtn>
                            </BlogSingleLayout>
                        </Col>
                    </Row>
                </BlogLayout>
            </Container>
        </BlogSection>
    )
}

export default Blog;