import React from "react";
import Banner from "../containers/HomePage/Banner";
import About from "../containers/HomePage/About";
import OurProjects from "../containers/HomePage/OurProjects";
import KeyServices from "../containers/HomePage/KeyServices";
import OurStats from "../containers/HomePage/OurStats";
import Testimonials from "../containers/HomePage/Testimonials";
import Clients from "../containers/HomePage/Clients";
import Blogs from "../containers/HomePage/Blogs";
import ContactUs from "../containers/ContactUs";
import Headermenu from "../containers/HeaderMenuBS";
import Footer from "../containers/Footer";
import GlobalStyle from "../components/global-styles";
import Fonts from "../components/fonts";
import "../components/layout.css";
import SEO from "../components/seo";

const Home = () => (
   <div>
        <Fonts/>
       <GlobalStyle />
       <main>
            <SEO 
               title="Landing Page | Pool Website Template | GatsbyJS Templates | Garnet"
               description="Clean and professional React GatsbyJS Pool Website template from Garnet"
            />
            <Headermenu />
            <Banner/>
            <About />
            <KeyServices />
            <OurProjects />
            <Testimonials />
            <OurStats />
            <Clients />
            <Blogs />
            <ContactUs />
            <Footer />
            
        </main>
   </div>

)

export default Home;
